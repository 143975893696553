import { graphql } from "gatsby";
import React from "react";

// External

// Internal
import Layout from "../components/layout";
import Seo from "../components/SEO";
import AroundTheWeb from "../containers/around-the-web-section";
import BlogSection from "../containers/blog-section";

export const query = graphql`
  query BLOG_QUERY_SINGLE {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 1
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        frontmatter {
          slug
          title
          description
          series
          cover_src
          date(formatString: "Do MMMM, YYYY")
          read_time
        }
      }
      totalCount
    }
  }
`;

const LinksPage = ({
  data: {
    allMdx: { nodes: blogPosts },
  },
}) => {
  return (
    <Layout>
      <Seo
        title="Links"
        description="Links for latest blogs and social media platforms"
      />
      <BlogSection title="Latest Article" blogPosts={blogPosts} />
      <AroundTheWeb />
    </Layout>
  );
};

export default LinksPage;
